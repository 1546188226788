
import Utils from "../../../utils";
import SettingsTasksPriority from "./list/priority";
import SettingsTasksCategory from "./list/category";
import SettingsTasksStatus from "./list/status";

export default class SettingsTasksList {
    private childs = {}

    constructor() {
        this.init();
    }

    async init() {
        this.bindListeners();
        this.childs = {
            priority: new SettingsTasksPriority(),
            category: new SettingsTasksCategory(),
            status: new SettingsTasksStatus(),
        }
        await Utils.hideLoader();
    }

    bindListeners() {
    }
}